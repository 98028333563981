import { doctorRoutes } from '@hc/routes';
import { useDoctorOnboarding, useViewPort } from '@hc/store';
import DoctorOnboardingImg from '@hc/ui/assets/doctorOnboarding.png';
import { Button, HealthCirclesLogo, Input, Label } from '@hc/ui/atoms';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useKeyPress } from 'ahooks';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { onboarding_content_card_style } from './style';

export function DoctorOnboarding() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    doctor,
    updateOnboarding,
    updateErrorOnboarding,
    isMailValidation,
    signUp,
    referralSignUp,
    verifyOnboardUrl,
    loading,
  } = useDoctorOnboarding(
    (state) => ({
      verifyOnboardUrl: state.verifyOnboardUrl,
      doctor: state.doctor,
      signUp: state.signUp,
      referralSignUp: state.referralSignUp,
      loading: state.loading,
      updateOnboarding: state.updateOnboarding,
      updateErrorOnboarding: state.updateErrorOnboarding,
      isMailValidation: state.isMailValidation,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const [data, setData] = useState({});
  const [showpassword, setPassword] = useState();
  const handleClickShowPassword = () => {
    setPassword(!showpassword);
  };
  const [showConfirmPassword, setConfirmPassword] = useState();
  const handleClickshowConfirmPassword = () => {
    setConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (val, key) => {
    updateOnboarding(key, val);
  };

  const onSignUp = async () => {
    const error = doctor?.error;
    if (!doctor?.password) {
      error.password = 'Enter the password';
      updateErrorOnboarding(error);
      return;
    }
    error.password = '';

    if (doctor?.password.length < 8) {
      error.password = 'Password must be at least 8 characters long';
      updateErrorOnboarding(error);
      return;
    }
    if (
      !doctor?.password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/,
      )
    ) {
      error.password =
        'Password must contain uppercase and lowercase letters, numbers, and special characters';
      updateErrorOnboarding(error);
      return;
    }
    error.password = '';

    // Checking Cofirm password
    if (!doctor?.confirmPassword) {
      error.confirmPassword = 'Enter the confirm password';
      updateErrorOnboarding(error);
      return;
    }
    error.confirmPassword = '';

    // Checking confirm and password are same
    if (doctor?.confirmPassword !== doctor?.password) {
      error.confirmPassword = 'Password does not matching, please check again';
      updateErrorOnboarding(error);
      return;
    }
    error.confirmPassword = '';
    error.confirmPassword = '';
    error.password = '';
    error.email = '';
    if (isMailValidation()) {
      if (data?.is_referral === true) {
        referralSignUp(data?.id, doctor?.password, doctor?.email);
      } else {
        signUp(data?.id, doctor?.password);
      }
    }
  };

  useKeyPress(['Enter'], (event) => {
    event.preventDefault();
    onSignUp();
  });

  const checkOnboardURL = async () => {
    const reponse = await verifyOnboardUrl();
    if (reponse?.isExpired === true) {
      toast("It looks like you've already signed up for Health Circles...", {
        icon: '⚠️',
      });
      navigate(doctorRoutes?.signin);
    }
  };

  useEffect(() => {
    if (searchParams.get('token')?.length > 0) {
      localStorage.clear();
      localStorage.setItem(
        localStorageKeys.authToken,
        searchParams.get('token'),
      );
      checkOnboardURL();
      const tokenData = parseJwt(searchParams.get('token'));
      setData(tokenData);
      updateOnboarding('email', tokenData?.profile?.email);
    }
  }, [location]);

  return (
    <Box sx={onboarding_content_card_style.rootSx}>
      {/* Right Side */}
      <Grid container sx={onboarding_content_card_style.totalSx}>
        <Grid
          item
          sm={isMobilePort ? 12 : 6}
          sx={onboarding_content_card_style.leftSideSx}
        >
          <Box sx={isMobilePort ? { background: '#000' } : ''}>
            <img
              src={DoctorOnboardingImg}
              alt=""
              style={
                isMobilePort
                  ? onboarding_content_card_style.rightImgMobileSx
                  : onboarding_content_card_style.rightImgSx
              }
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={isMobilePort ? 12 : 6}
          sm={isMobilePort ? 12 : 6}
          sx={onboarding_content_card_style.rightSideSx}
        >
          <Box
            sx={
              isMobilePort
                ? onboarding_content_card_style.CardcontentMobileSx
                : onboarding_content_card_style.CardcontentSx
            }
          >
            <Box display="flex" gap={0.5} pb={2.5}>
              <HealthCirclesLogo />
              <Typography
                sx={{
                  color: 'primary.main',
                  fontSize: '16px',
                  fontWeight: 600,
                }}
              >
                Health Circles
              </Typography>
            </Box>
            <Box sx={onboarding_content_card_style.formBoxSx}>
              <Typography
                variant="h5"
                sx={onboarding_content_card_style.titleSx}
              >
                Create Account
              </Typography>
            </Box>

            <Box>
              <Label htmlFor="emailInput">Email</Label>
              <Input
                value={doctor?.email ?? ''}
                id="email"
                isReadOnly={data?.is_referral === true ? false : true}
                isError={doctor?.error.email !== ''}
                helperText={doctor?.error.email}
                onChange={(value) => handleChange(value.target.value, 'email')}
              />
            </Box>
            <Box sx={onboarding_content_card_style.inputGroupSx}>
              <Label htmlFor="password" isRequired>
                Create password
              </Label>
              <Input
                id="newPassword"
                type={showpassword ? 'text' : 'password'}
                onChange={(e) => handleChange(e.target.value, 'password')}
                isError={doctor?.error.password}
                errorMessage={doctor?.error.password}
                value={doctor?.password}
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword()}
                    edge="end"
                  >
                    {showpassword ? (
                      <MdVisibilityOff htmlColor="#848484" />
                    ) : (
                      <MdVisibility htmlColor="#848484" />
                    )}
                  </IconButton>
                }
              />
            </Box>
            <Box sx={onboarding_content_card_style.inputGroupSx}>
              <Label htmlFor="confirmPassword" isRequired>
                Confirm password
              </Label>
              <Input
                id="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={(e) =>
                  handleChange(e.target.value, 'confirmPassword')
                }
                isError={!!doctor?.error.confirmPassword}
                errorMessage={doctor?.error.confirmPassword}
                value={doctor?.confirmPassword}
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickshowConfirmPassword()}
                    edge="end"
                  >
                    {showConfirmPassword ? (
                      <MdVisibilityOff htmlColor="#848484" />
                    ) : (
                      <MdVisibility htmlColor="#848484" />
                    )}
                  </IconButton>
                }
              />
            </Box>
            <Box sx={{ mt: 3, display: 'grid', gap: 3 }}>
              <Button
                fullWidth
                buttonStyle={onboarding_content_card_style.buttonStyleSx}
                onClick={() => onSignUp()}
                loading={loading}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
