/* eslint-disable no-sparse-arrays */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { queryClient, routeTo } from '@hc/utils';

import { doctorRoutes } from '@hc/routes';

import { httpRequest } from '@hc/utils/axios';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { toast } from 'react-hot-toast';

import { create } from 'zustand';
import { useRouting } from '../common/routing';

export const useDoctorOnboarding = create((set, get) => ({
  doctor: {
    email: '',
    password: '',
    confirmpassword: '',
    name: '',
    qualification: '',
    working_at: [],
    speciality: '',
    serving_from: new Date(),
    register_no: '',
    state_medical_council_name: '',
    year_of_registration: '',
    address: '',
    language: [],
    about: '',
    selectSpeciality: [],
    selectLanguage: [],
    country_code: '',
    mobile_no: '',
    oauth_url: '',
    googleAuthToken: '',
    error: {
      email: '',
      password: '',
      confirmpassword: '',
      name: '',
      qualification: '',
      working_at: '',
      speciality: '',
    },
  },
  activeIndex: 0,
  nextActive: false,
  loading: null,
  error: null,
  setPersistedDoctorData: (payload) => {
    set((state) => {
      const { doctor } = state;
      const { password, confirmpassword, ...rest } = doctor;
      return {
        doctor: {
          ...rest,
          ...payload,
        },
      };
    });
  },
  handleNext: () => {
    // validation
    const { activeIndex } = get();
    set({
      activeIndex: activeIndex + 1,
    });
  },
  handleBack: () => {
    const { activeIndex } = get();
    set({
      activeIndex: activeIndex - 1,
    });
  },
  handleOnboardingDataChange: (key, value) => {
    const { doctor, isIamValided } = get();
    const doctorCopy = JSON.parse(JSON.stringify(doctor));
    set({
      doctor: {
        ...doctorCopy,
        [key]: value,
      },
    });
    return isIamValided(key);
  },

  updateOnboardingError: (error) => {
    const { doctor } = get();
    set({
      doctor: {
        ...doctor,
        error,
      },
    });
  },

  updateOnboardingFielsError: (key) => {
    const { doctor } = get();
    set({
      doctor: {
        ...doctor,
        error: {
          ...doctor?.error,
          [key]: '',
        },
      },
    });
  },

  addSpeciality: (e, option) => {
    const { doctor, isIamValided } = get();
    const { selectSpeciality } = doctor;
    let specialityDataCopy = JSON.parse(JSON.stringify(selectSpeciality));
    if (specialityDataCopy.length > 0) {
      const specialityDataFilter = specialityDataCopy.filter(
        (v) => v?.value === option?.value,
      );
      if (!specialityDataFilter?.length > 0) {
        specialityDataCopy.push(option);
      } else {
        const specialityDataFilter = specialityDataCopy.filter(
          (v) => v?.value !== option?.value,
        );
        specialityDataCopy = specialityDataFilter;
      }
    } else {
      specialityDataCopy.push(option);
    }
    set({
      doctor: {
        ...doctor,
        selectSpeciality: specialityDataCopy,
      },
    });
    return isIamValided('speciality');
  },

  addLanguage: (e, option) => {
    const { doctor } = get();
    const { selectLanguage } = doctor;
    let languageDataCopy = JSON.parse(JSON.stringify(selectLanguage));
    if (languageDataCopy.length > 0) {
      const languageDataFilter = languageDataCopy.filter(
        (v) => v?.value === option?.value,
      );
      if (!languageDataFilter?.length > 0) {
        languageDataCopy.push(option);
      } else {
        const specialityDataFilter = languageDataCopy.filter(
          (v) => v?.value !== option?.value,
        );
        languageDataCopy = specialityDataFilter;
      }
    } else {
      languageDataCopy.push(option);
    }
    set({
      doctor: {
        ...doctor,
        selectLanguage: languageDataCopy,
      },
    });
  },

  clearBasicInfoState: () => {
    set({
      doctor: {
        email: '',
        password: '',
        confirmpassword: '',
        name: '',
        qualification: '',
        working_at: [],
        speciality: '',
        serving_from: new Date(),
        register_no: '',
        state_medical_council_name: '',
        year_of_registration: '',
        address: '',
        language: [],
        about: '',
        selectSpeciality: [],
        selectLanguage: [],
        country_code: '',
        mobile_no: '',
        oauth_url: '',
        googleAuthToken: '',
        error: {
          email: '',
          password: '',
          confirmpassword: '',
          name: '',
          qualification: '',
          working_at: '',
          speciality: '',
        },
      },
    });
  },

  // DELETE DATA
  clearBasicInfo: (key) => {
    const { doctor, isIamValided } = get();
    set({
      doctor: { ...doctor, [key]: [] },
    });
    return isIamValided();
  },

  // ADD WORKING AT
  addWorkingAt: (text) => {
    const { doctor } = get();
    const { working_at } = doctor;
    const working_atCopy = JSON.parse(JSON.stringify(working_at));
    working_atCopy.push(text);
    set({
      doctor: {
        ...doctor,
        working_at: working_atCopy,
      },
    });
  },

  // DELETE DATA
  deleteData: (type, i) => {
    const { doctor, isIamValided } = get();
    const copyData = JSON.parse(JSON.stringify(doctor?.[type]));
    copyData.splice(i, 1);
    set({
      doctor: {
        ...doctor,
        [type]: copyData,
      },
    });
    return isIamValided();
  },

  isIamValided: (key) => {
    const { doctor } = get();
    const doctorCopy = JSON.parse(JSON.stringify(doctor));
    const error = doctorCopy?.error;

    const fieldsToValidate = {
      name: {
        errorMessage: 'Please enter your name',
        value: doctor?.name,
      },
      qualification: {
        errorMessage: 'Qualification is required',
        value: doctor?.qualification,
      },
      working_at: {
        errorMessage: 'Work location is required',
        value: doctor?.working_at,
      },
      speciality: {
        errorMessage: 'Speciality is required',
        value: doctor?.selectSpeciality,
      },
    };

    let isValid = true;
    // Loop over the fields to validate
    Object.entries(fieldsToValidate).forEach(
      ([field, { errorMessage, value }]) => {
        // If a key is provided, only validate that field
        if (key && key !== field) {
          return;
        }
        // If the current field is invalid, set the isValid flag to false and set the error message
        if (!value || value.length === 0) {
          isValid = false;
          error[field] = errorMessage;
        } else {
          error[field] = '';
        }
      },
    );

    set({
      doctor: {
        ...doctor,
        error: {
          ...error,
        },
      },
    });

    return isValid;
  },
  // validate
  isMailValidation: () => {
    const { doctor } = get();
    const doctorCopy = JSON.parse(JSON.stringify(doctor));
    let isValid = true;
    const error = doctorCopy?.error;

    // Checking email
    const filter = /\S+@\S+\.\S+/;
    if (doctor?.email?.length === 0) {
      isValid = false;
      error.email = 'Please enter mail';
    } else if (!filter.test(doctor?.email)) {
      isValid = false;
      error.email = 'Please enter the valid mail';
    } else {
      error.email = '';
    }

    set({
      doctor: {
        ...doctor,
        error,
      },
    });
    return isValid;
  },

  // validate
  isPasswordValidation: () => {
    const { doctor } = get();
    const doctorCopy = JSON.parse(JSON.stringify(doctor));
    let isValid = true;
    const error = doctorCopy?.error;

    if (doctor?.password?.length === 0) {
      isValid = false;
      error.password = 'Enter the password';
    } else {
      error.password = '';
    }

    if (doctor?.password.length < 8) {
      isValid = false;
      error.password = 'Password must be at least 8 characters long';
    } else {
      error.password = '';
    }
    if (
      !doctor?.password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/,
      )
    ) {
      isValid = false;
      error.password =
        'Password must contain uppercase and lowercase letters, numbers, and special characters';
    } else {
      error.password = '';
    }

    // Checking Cofirm password
    if (doctor?.confirmPassword?.length === 0) {
      isValid = false;
      error.confirmPassword = 'Enter the confirm password';
    } else {
      error.confirmPassword = '';
    }

    // Checking confirm and password are same
    if (doctor?.confirmPassword !== doctor?.password) {
      isValid = false;
      error.confirmPassword = "Password doesn't matching, please check again";
    } else {
      error.confirmPassword = '';
    }
    set({
      doctor: {
        ...doctor,
        error,
      },
    });
    return isValid;
  },

  setPassword: async () => {},
  doctorDetails: async () => {},

  loadingUpdate: (val) => {
    set({ loading: val });
  },
  // SignUp
  signUp: async (user_id, password) => {
    try {
      set({ loading: true });
      // Hitting the signup API
      const response = await httpRequest(
        'post',
        `${envConfig.auth_url}/doctor-set-new-password`,
        {
          user_id: user_id ?? '',
          password: password ?? '',
        },
        true,
      );
      // If the user is new
      if (response?.data?.status?.code === 200) {
        set({ loading: false });
        localStorage.setItem(
          localStorageKeys.authToken,
          response?.data?.data?.auth_token,
        );
        toast.success(response?.data?.status?.message);
        return routeTo(useRouting, doctorRoutes.onboarding);
      }
      return set({ loading: false });
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  // SignUp
  referralSignUp: async (user_id, password, emailId) => {
    try {
      set({ loading: true });
      // Hitting the signup API
      const response = await httpRequest(
        'post',
        `${envConfig.auth_url}/referral-doctor-set-new-password`,
        {
          user_id: user_id ?? '',
          password: password ?? '',
          email_id: emailId ?? '',
        },
        true,
      );
      // If the user is new
      if (response?.data?.status?.code === 200) {
        set({ loading: false });
        localStorage.setItem(
          localStorageKeys.authToken,
          response?.data?.data?.auth_token,
        );
        toast.success(response?.data?.status?.message);
        return routeTo(useRouting, doctorRoutes.onboarding);
      }
      return set({ loading: false });
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  verifyOnboardUrl: async () => {
    try {
      set({ verifyingInviteToken: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['verifyOnboardUrl'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.auth_url}/invite-link-expired-check`,
            {},
            true,
          );
          return data;
        },
        staleTime: 30000,
      });
      set({ verifyingInviteToken: false });
      if (data?.isExpired === true) {
        localStorage.clear();
      }
      return data;
    } catch (err) {
      set({ verifyingInviteToken: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  // SignIn
  signIn: async (payload) => {
    try {
      set({ loading: true });
      // Hitting the signin API
      const response = await httpRequest(
        'post',
        `${envConfig.auth_url}/doctor-login`,
        {
          email: payload?.email ?? '',
          password: payload?.password ?? '',
        },
        false,
      );
      // If the user is exists
      if (
        response?.data?.status?.code === '200' &&
        response?.data?.data?.auth_token
      ) {
        const token = response?.data?.data?.auth_token;
        localStorage.setItem(localStorageKeys.authToken, token);
        const data = parseJwt(token);
        toast.success('Signed in successfully');
        set({ loading: false });

        if (!data?.is_doctor_onboarded) {
          return routeTo(useRouting, doctorRoutes.onboarding);
        }
        return routeTo(useRouting, doctorRoutes.home);
      }
      if (response?.data?.status?.code === '401') {
        toast.error(
          response?.data?.status?.message ??
            'Invalid Email and Password combination',
        );
      }
      return set({ loading: false });
    } catch (err) {
      set({ loading: false });
      set({
        user: payload,
      });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  // GET OAUTH URL
  getOauthURL: async () => {
    try {
      set({ loading: true });
      // Hitting the signin API
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/calendar/oauth_url`,
        {},
        true,
      );
      // If the user is exists
      if (
        response?.data?.statusCode === '200' &&
        response?.data?.data?.sign_in_url
      ) {
        const { doctor } = get();
        set({
          doctor: {
            ...doctor,
            oauth_url: response?.data?.data?.sign_in_url,
          },
        });
        return response;
      }
      set({ loading: false });
      return response;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  forgotPassword: async (mail_id) => {
    try {
      set({ loading: true });
      // Hitting the signin API
      const response = await httpRequest(
        'post',
        `${envConfig.auth_url}/doctor-reset-password`,
        {
          email: mail_id ?? '',
        },
        false,
      );
      // If the user is exists
      if (
        response?.data?.status?.code === '200' &&
        response?.data?.status?.message ===
          'Sent a reset link to your Email ID. Please check'
      ) {
        toast.success(
          response?.data?.status?.message ??
            'Sent a reset link to your Email ID. Please check',
        );
      } else if (
        response?.data?.status?.code === '200' &&
        response?.data?.status?.message === 'No User Found for the given email.'
      ) {
        toast.error(
          response?.data?.status?.message ??
            'No User Found for the given email.',
        );
      }
      return set({ loading: false });
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  resetPassword: async (passwor, tokenData) => {
    try {
      set({ loading: true });

      // Hitting the signin API
      const response = await httpRequest(
        'post',
        `${envConfig.auth_url_v1}/update_password`,
        {
          password: passwor,
          token: tokenData,
        },
        false,
      );
      // If the user is exists
      if (response?.data?.statusCode === '200') {
        toast.success(
          response?.data?.message ??
            'Your password has been updated successfully.',
        );
      } else if (response?.data?.statusCode === '401') {
        toast.error(response?.data?.message ?? 'Opps! Link has been expired.');
      }
      set({ loading: false });
      return response?.data?.statusCode;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  // updateOnboarding
  updateOnboarding: (key, value) => {
    const { doctor } = get();
    set({
      doctor: {
        ...doctor,
        [key]: value,
      },
    });
  },

  // updateErrorOnboarding
  updateErrorOnboarding: (error) => {
    const { doctor } = get();
    set({
      doctor: {
        ...doctor,
        error: {
          ...doctor.error,
          error,
        },
      },
    });
  },

  // updateState
  updateStateOnBoarding: () => {
    set((state) => ({
      doctor: {
        ...state?.doctor,
        email: '',
        password: '',
      },
      loading: false,
    }));
  },
}));
