import { getDateFormat } from '@hc/dayjs';
import { useDoctorOnboarding, useDoctorProfile, useViewPort } from '@hc/store';
import stethoscope from '@hc/ui/assets/stethoscope.svg';
import Wave from '@hc/ui/assets/Wave.svg';
import {
  CameraDocIcon,
  CloseIcon,
  EmailIcon,
  GoogleCalender,
  InfoIcon,
  Loader,
  PhoneIcon,
  RegisterIcon,
  StateCoucilIcon,
  TrashIcon,
} from '@hc/ui/atoms';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { DisconnectCalendarModal } from '../DisconnectCalendarModal';
import { ModelPopup } from '../modelPopup';
import { viewProfile_style } from './style';

function ViewProfile(props) {
  const {
    className = '',
    profile_pic = '',
    emailId = '',
    isRxAudit,
    mobile = '',
    rootStyle = {},
    ...rest
  } = props;

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);

  const location = useLocation();

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const {
    deleteProfileImage,
    profilePictureUpsert,
    profile,
    updateGoogleCalendar,
    loadingPhoto,
  } = useDoctorProfile((state) => ({
    deleteProfileImage: state.deleteProfileImage,
    profilePictureUpsert: state.profilePictureUpsert,
    profile: state.profile,
    updateGoogleCalendar: state.updateGoogleCalendar,
    loading: state.loading,
    loadingPhoto: state.loadingPhoto,
  }));
  const { sync_mail, sync_mail_update } = profile;
  const [disconnect, setdisconnect] = useState(false);

  const modalClose = () => {
    setdisconnect(false);
  };

  const DisconnectGoogleCalender = async () => {
    queryClient.invalidateQueries({
      queryKey: ['googleCalendarSync'],
    });
    const status = await updateGoogleCalendar('');
    if (status?.code === 200) {
      toast.success(status?.message);
      setdisconnect(false);
    } else {
      toast.error('Something went wrong please try again!');
    }
  };

  const { getOauthURL } = useDoctorOnboarding((state) => ({
    getOauthURL: state.getOauthURL,
  }));

  const [trashDrawerOpen, setTrashDrawerOpen] = useState(false);
  let windowObjectReference = null;
  let previousUrl = null;

  const handleProfilePicUpload = async (e) => {
    await profilePictureUpsert(e, tokenData?.user_id, 'doctorProfile');
  };

  // Profile Picture Deleted
  const removeProfilePic = async () => {
    const response = await deleteProfileImage(
      tokenData?.user_id,
      'doctorProfile',
    );

    if (response?.data?.status?.code === 200) {
      setTrashDrawerOpen(false);
    } else {
      return toast.error('Something went wrong please try again!');
    }
  };

  const receiveMessage = async (event) => {
    // Do we trust the sender of this message? (might be
    // different from what we originally opened, for example).
    if (event.origin !== window.location.origin) {
      toast.error('Something went wrong please try again!');
      return;
    }
    const { data } = event;
    // if we trust the sender and the source is our popup
    if (data?.code && data?.code?.length > 0) {
      queryClient.invalidateQueries({
        queryKey: ['googleCalendarSync'],
      });
      const resStatus = await updateGoogleCalendar(data?.code ?? '');
      if (resStatus?.code === 200) {
        toast.success(resStatus?.message);
      } else {
        toast.error('Something went wrong please try again!');
      }
    }
  };

  const onGoogleIntegerationClicked = async () => {
    const response = await getOauthURL();
    if (
      response?.data?.statusCode === '200' &&
      response?.data?.data?.sign_in_url
    ) {
      const url = response?.data?.data?.sign_in_url;
      const name = 'Google Calendar Integeration';

      // remove any existing event listeners
      window.removeEventListener('message', receiveMessage);

      // window features
      const strWindowFeatures =
        'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

      if (windowObjectReference === null || windowObjectReference.closed) {
        /* if the pointer to the window object in memory does not exist
         or if such pointer exists but the window was closed */
        windowObjectReference = window.open(url, name, strWindowFeatures);
      } else if (previousUrl !== url) {
        /* if the resource to load is different,
         then we load it in the already opened secondary window and then
         we bring such window back on top/in front of its parent window. */
        windowObjectReference = window.open(url, name, strWindowFeatures);
        windowObjectReference.focus();
      } else {
        /* else the window reference must exist and the window
         is not closed; therefore, we can bring it back on top of any other
         window with the focus() method. There would be no need to re-create
         the window or to reload the referenced resource. */
        windowObjectReference.focus();
      }
      // add the listener for receiving a message from the popup
      window.addEventListener(
        'message',
        (event) => receiveMessage(event),
        false,
      );
      // assign the previous URL
      previousUrl = url;
    }
  };

  return (
    <Box
      sx={{
        ...viewProfile_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Stack
        p={isMobilePort ? 1 : 4}
        sx={{
          backgroundImage: isMobilePort ? null : `url(${Wave})`,
          ...viewProfile_style.parentwrap,
          ...(isMobilePort && viewProfile_style.responsiveparentwrap),
        }}
      >
        <Box
          height={isMobilePort ? 50 : 200}
          sx={viewProfile_style.totalProfileSx}
        >
          <Badge
            sx={{ cursor: 'pointer' }}
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={
              !loadingPhoto ? (
                <label htmlFor="uplodebtn">
                  <Box
                    sx={
                      isMobilePort
                        ? viewProfile_style.responsivecameraSx
                        : viewProfile_style.cameraSx
                    }
                  >
                    {' '}
                    {profile_pic ? (
                      <TrashIcon
                        rootStyle={{ color: 'common.white', cursor: 'pointer' }}
                        onClick={() => setTrashDrawerOpen(true)}
                      />
                    ) : (
                      <CameraDocIcon
                        rootStyle={{ color: 'common.white', cursor: 'pointer' }}
                      />
                    )}
                    {!profile_pic && (
                      <input
                        hidden
                        onChange={handleProfilePicUpload}
                        type="file"
                        id="uplodebtn"
                        name="img"
                        accept="image/*"
                      />
                    )}
                  </Box>
                </label>
              ) : (
                <Loader />
              )
            }
          >
            <Avatar
              sx={
                isMobilePort
                  ? viewProfile_style.responsiveprofileSx
                  : viewProfile_style.profileSx
              }
              alt="profile_pic"
              src={profile_pic}
            >
              <img src={stethoscope} width={100} alt="" />
            </Avatar>
          </Badge>
        </Box>
        <Box
          sx={{
            ...viewProfile_style.totalDescriptionSx,
          }}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            sx={{
              ...viewProfile_style.contactIcon,
              my: isMobilePort ? 2 : 3.5,
              mt: isMobilePort && 8,
            }}
          >
            <Avatar sx={viewProfile_style.iconSx}>
              <EmailIcon rootStyle={viewProfile_style.detailIconSx} />
            </Avatar>

            <Box style={{ textAlign: 'left' }}>
              <Typography
                sx={{
                  ...viewProfile_style.editSx,
                  fontSize: isMobilePort ? '12px' : '14px',
                }}
              >
                Mail ID
              </Typography>
              <Tooltip title={emailId ?? ''} arrow placement="bottom-end">
                <Typography
                  sx={{
                    ...viewProfile_style.editdesSx,
                    fontSize: isMobilePort ? '12px' : '14px',
                  }}
                >
                  {emailId ?? ''}{' '}
                </Typography>
              </Tooltip>
            </Box>
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            sx={{
              ...viewProfile_style.contactIcon,
              my: isMobilePort ? 2 : 3.5,
            }}
          >
            <Avatar sx={viewProfile_style.iconSx}>
              <PhoneIcon rootStyle={viewProfile_style.detailIconSx} />
            </Avatar>
            <Box style={{ textAlign: 'left' }}>
              <Typography
                sx={{
                  ...viewProfile_style.editSx,
                  fontSize: isMobilePort ? '12px' : '14px',
                }}
              >
                Mobile No
              </Typography>
              <Typography
                sx={{
                  ...viewProfile_style.editdesSx,
                  fontSize: isMobilePort ? '12px' : '14px',
                }}
              >
                {mobile}
              </Typography>
            </Box>
          </Stack>

          <Stack
            flexDirection="row"
            alignItems="center"
            sx={{
              ...viewProfile_style.contactIcon,
              my: isMobilePort ? 2 : 3.5,
            }}
          >
            <Avatar sx={viewProfile_style.iconSx}>
              <RegisterIcon rootStyle={viewProfile_style.detailIcon} />
            </Avatar>
            <Box style={{ textAlign: 'left' }}>
              <Typography
                sx={{
                  ...viewProfile_style.editSx,
                  fontSize: isMobilePort ? '12px' : '14px',
                }}
              >
                Register No
              </Typography>
              <Typography
                sx={{
                  ...viewProfile_style.editdesSx,
                  fontSize: isMobilePort ? '12px' : '14px',
                }}
              >
                {profile?.register_no ?? ''}
              </Typography>
            </Box>
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            sx={{
              ...viewProfile_style.contactIcon,
              my: isMobilePort ? 2 : 3.5,
            }}
          >
            <Avatar sx={viewProfile_style.iconSx}>
              <StateCoucilIcon rootStyle={viewProfile_style.detailIcon} />
            </Avatar>
            <Box style={{ textAlign: 'left' }}>
              <Typography
                sx={{
                  ...viewProfile_style.editSx,
                  fontSize: isMobilePort ? '12px' : '14px',
                }}
              >
                State Medical Council{' '}
              </Typography>
              <Typography
                sx={{
                  ...viewProfile_style.editdesSx,
                  fontSize: isMobilePort ? '12px' : '14px',
                }}
              >
                {`${profile?.state_medical_council_name} (${profile?.year_of_registration})`}
              </Typography>
            </Box>
          </Stack>
          {!isRxAudit && (
            <Box>
              <Stack flexDirection="row" alignItems="center">
                <Box
                  sx={{
                    ...(sync_mail
                      ? viewProfile_style.GoogleConnectActiveSx
                      : viewProfile_style.GoogleConnectSx),
                    width: isMobilePort ? '100%' : 'fit-content',
                  }}
                  onClick={() =>
                    sync_mail ? null : onGoogleIntegerationClicked()
                  }
                >
                  <GoogleCalender />
                  <Typography
                    sx={{
                      ...(sync_mail
                        ? viewProfile_style.googleconnectActiveTextSx
                        : viewProfile_style.googleconnectTextSx),
                    }}
                  >
                    {sync_mail
                      ? sync_mail.length <= 22
                        ? sync_mail
                        : `${sync_mail.slice(0, 22)}...`
                      : 'connect to google calendar'}
                  </Typography>
                  {sync_mail && (
                    <CloseIcon
                      rootStyle={{ color: '#F44F5A' }}
                      onClick={() => setdisconnect(true)}
                    />
                  )}
                </Box>
                {sync_mail && (
                  <Box ml={1} sx={viewProfile_style.centerSx}>
                    {' '}
                    <Tooltip
                      title={
                        <div>
                          You can disconnect
                          <br />
                          and connect new mail
                        </div>
                      }
                    >
                      <IconButton disableFocusRipple disableRipple>
                        <InfoIcon />{' '}
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Stack>

              {sync_mail && sync_mail_update && (
                <Box sx={viewProfile_style.lastseenContainerSx}>
                  <Typography sx={viewProfile_style.synctitleSx}>
                    Last synced
                  </Typography>
                  <Typography sx={viewProfile_style.synctimeSx}>
                    {' '}
                    {getDateFormat(sync_mail_update, 'DD MMM YYYY, hh:mm A')}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Stack>

      <DisconnectCalendarModal
        isModalOpen={disconnect}
        handleClose={modalClose}
        handleRemove={DisconnectGoogleCalender}
      />
      <ModelPopup
        // icons={<WarningIcon />}
        open={trashDrawerOpen}
        subTitleStyle={{
          fontWeight: 500,
        }}
        subTitle="Remove profile picture?"
        acceptButtonName="Remove"
        declineButtonName="Cancel"
        notifyBtnStyletwo={{
          bgcolor: 'primaryTints.200',
          color: 'primary.main',
          '&:hover': {
            bgcolor: 'primaryTints.200',
          },
        }}
        notifyBtnStyle={{
          bgcolor: 'primary.main',
          color: 'common.white',
        }}
        onDecline={() => setTrashDrawerOpen(false)}
        onAccpet={removeProfilePic}
      />
    </Box>
  );
}

ViewProfile.propTypes = {
  handleProfilePicUpload: PropTypes.func,
  name: PropTypes.string,
  profile_pic: PropTypes.string,
  emailId: PropTypes.string,
  mobile: PropTypes.string,
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isRxAudit: PropTypes.bool,
};

export { ViewProfile };
