/* eslint-disable no-lonely-if */
/* eslint-disable prefer-const */
import { doctorRoutes } from '@hc/routes';
import { useRouting, useViewPort } from '@hc/store';
import { useDoctorChatConsultation } from '@hc/store/doctor/chatConsultation';
import {
  ChatConstructData,
  localStorageKeys,
  parseJwt,
  queryClient,
} from '@hc/utils';
import { useSocket } from '@hc/utils/socket';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Toaster } from 'react-hot-toast';
import { Outlet, useNavigate } from 'react-router-dom';
import { DoctorAcceptReject } from '../doctorAcceptReject';
import { DoctorApproveModal } from '../doctorApproveModal';
import { rootLayout_style } from './style';

export function DoctorRootLayout(props) {
  const {
    className = '',
    children,
    backgroundStyle,
    rootStyle,
    ...rest
  } = props;

  // Socket call
  const socket = useSocket();
  const [acceptReject, setAcceptReject] = useState(false);
  const [approval, setApproval] = useState(false);
  const [type, setType] = useState('');

  const navigate = useNavigate();
  const route = useRouting((state) => state.route);
  const [isMobile, setIsmobile] = useState();

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);

  const { setValue, isMobilePort } = useViewPort((state) => ({
    setValue: state.setValue,
    isMobilePort: state.isMobilePort,
  }));

  const {
    updateDoctorChatConsultationState,
    doctorChatConsultationState,
    getChatCounsultationById,
    getLocalStorage,
  } = useDoctorChatConsultation(
    (state) => ({
      getChatCounsultationById: state.getChatCounsultationById,
      updateDoctorChatConsultationState:
        state.updateDoctorChatConsultationState,
      doctorChatConsultationState: state.doctorChatConsultationState,
      getLocalStorage: state.getLocalStorage,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  const { chatStatusData } = doctorChatConsultationState;

  // common socket data for useEffect
  const commonSocketData = (eventData) => ({
    doctorProfileId: eventData.doctorProfileId,
    doctorUserId: eventData.doctorUserId,
    doctorProfilePicture: eventData.doctorProfilePicture,
    doctorName: eventData.doctorName,
    doctorMobileNo: eventData.doctorMobileNo,
    doctorChatConsultationAmount: eventData.doctorChatConsultationAmount,
    chatRequestedDate: eventData.chatRequestedDate,
    chatConsultationDuration: eventData.chatConsultationDuration,
    patientUserId: eventData.patientUserId,
    patientProfileId: eventData.patientProfileId,
    patientProfilePicture: eventData.patientProfilePicture,
    patientName: eventData.patientName,
    patientMobileNo: eventData.patientMobileNo,
    type: eventData.type,
    chatConsultationRequestsId: eventData.chatConsultationRequestsId,
  });

  const {
    doctorProfileId = '',
    doctorUserId = '',
    doctorProfilePicture = '',
    doctorName = '',
    doctorMobileNo = '',
    doctorChatConsultationAmount = 0,
    chatRequestedDate = '',
    chatConsultationDuration = '',
    patientUserId = '',
    patientProfileId = '',
    patientProfilePicture = '',
    patientName = '',
    patientMobileNo = '',
    // type,
    chatConsultationRequestsId = '',
  } = chatStatusData;

  const acceptRejectCloseFnc = () => {
    setAcceptReject(false);
  };

  const approvalCloseFnc = () => {
    setApproval(false);
  };
  const seenChatEndConfirmationFnc = () => {
    if (type === 'cancel' || type === 'end_by_patient') {
      localStorage.removeItem('chatTimerMins');
      localStorage.removeItem('chatTimerMins');
      localStorage.removeItem('isChatTimer');
      updateDoctorChatConsultationState('chatStatusData', {});
      navigate(doctorRoutes?.home);
    }
    setApproval(false);
  };
  const seenChatCancelConfirmationFnc = () => {
    setType('');
    setApproval(false);
  };

  const getChatDetails = async (consultation_id) => {
    // queryClient?.invalidateQueries({
    //   queryKey: ['chat_consultation_history_by_id'],
    // });
    queryClient.invalidateQueries({
      queryKey: ['chat_consultation_data_by_id'],
    });
    // await getChatHistoryDataById(consultation_id, chatStatus);
    await getChatCounsultationById(consultation_id);
  };

  const startChatFnc = async () => {
    setType('');
    setApproval(false);
    // call socket for chat start by doctor
    await socket.emit('chat_start_by_doctor', {
      doctorProfileId,
      doctorUserId,
      doctorProfilePicture,
      doctorName,
      doctorMobileNo,
      doctorChatConsultationAmount,
      chatRequestedDate,
      chatConsultationDuration,
      patientUserId,
      patientProfileId,
      patientProfilePicture,
      patientName,
      patientMobileNo,
      type: 'chatStartByDoctor',
      chatConsultationRequestsId,
      chatConsultationId: localStorage.getItem('chatConsultationId'),
    });
    updateDoctorChatConsultationState('chatTimer', true);
    updateDoctorChatConsultationState('chatStatus', 'OnGoing');
    getChatDetails(localStorage.getItem('chatConsultationId'));
    localStorage.setItem('isChatTimer', true);
    navigate(doctorRoutes?.doctorChatPage);
  };

  const makeOfflineFnc = () => {
    setType('');
    setApproval(false);
    // Socket call for availability status change
    socket.emit('doctor_availability_status_change', {
      doctorProfileId,
      type: 'offline',
    });
  };

  // ------------Media query ------------------
  const mediaQueryList = window.matchMedia('(max-width: 768px)');
  mediaQueryList.addEventListener('change', () => {
    if (mediaQueryList.matches) {
      setIsmobile(true);
      // localStorage.setItem(localStorageKeys?.isMobilePort, true);
      setValue('isMobilePort', true);
    } else {
      setIsmobile(false);
      // localStorage.setItem(localStorageKeys?.isMobilePort, false);
      setValue('isMobilePort', false);
    }
  });

  const onRejectRequest = () => {
    socket.emit('chat_request_reject_by_doctor', {
      doctorProfileId,
      doctorUserId,
      doctorProfilePicture,
      doctorName,
      doctorMobileNo,
      doctorChatConsultationAmount,
      chatRequestedDate,
      chatConsultationDuration,
      patientUserId,
      patientProfileId,
      patientProfilePicture,
      patientName,
      patientMobileNo,
      type: 'chatRequestRejectByDoctor',
      chatConsultationRequestsId,
    });
    setAcceptReject(false);
    if (isMobilePort) {
      navigate(doctorRoutes?.doctorChatStatus, { state: { type: 'reject' } });
    } else {
      setType('reject');
      setApproval(true);
    }
  };

  const onAcceptRequest = () => {
    // Socket call for availability status change
    socket.emit('doctor_availability_status_change', {
      doctorProfileId,
      type: 'busy',
    });
    socket.emit('chat_request_accept_by_doctor', {
      doctorProfileId,
      doctorUserId,
      doctorProfilePicture,
      doctorName,
      doctorMobileNo,
      doctorChatConsultationAmount,
      chatRequestedDate,
      chatConsultationDuration,
      patientUserId,
      patientProfileId,
      patientProfilePicture,
      patientName,
      patientMobileNo,
      type: 'chatRequestAcceptByDoctor',
      chatConsultationRequestsId,
    });
    setAcceptReject(false);
    if (isMobilePort) {
      navigate(doctorRoutes?.doctorChatStatus, { state: { type: 'accept' } });
    } else {
      setType('accept');
      setApproval(true);
    }
  };

  useEffect(() => {
    if (route !== null) {
      navigate(route);
      setTimeout(() => {
        useRouting.setState({ route: null });
      }, 1000);
    }
  }, [route]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setValue('isMobilePort', true);
    } else {
      setValue('isMobilePort', false);
    }
    // GET & SET doctorChatStatusData from local storage
    getLocalStorage();
  }, []);

  useEffect(() => {
    if (socket) {
      // Patient request the chat request to doctor
      socket.on('patient_request_the_chat_request_to_doctor', (eventData) => {
        if (tokenData?.user_profile_id === eventData?.doctorProfileId) {
          const data = {
            ...commonSocketData(eventData),
          };
          updateDoctorChatConsultationState('chatStatusData', data);
          setAcceptReject(true);
        }
      });

      // Chat request cancel by patient
      socket.on('chat_request_cancel_by_patient', (eventData) => {
        if (tokenData?.user_profile_id === eventData?.doctorProfileId) {
          const data = {
            ...commonSocketData(eventData),
          };
          updateDoctorChatConsultationState('chatStatusData', data);
          setAcceptReject(false);
          setType('cancel');
          setApproval(true);
        }
      });

      // Chat payment initiate by patient
      socket.on('chat_payment_initiate_by_patient', (eventData) => {
        if (tokenData?.user_profile_id === eventData?.doctorProfileId) {
          const data = {
            ...commonSocketData(eventData),
            chatConsultationRequestPaymentId:
              eventData.chatConsultationRequestPaymentId,
          };
          updateDoctorChatConsultationState('chatStatusData', data);
          setAcceptReject(false);
          setType('payment_initiated');
          setApproval(true);
        }
      });

      // Chat payment completed by patient
      socket.on('chat_payment_completed_by_patient', (eventData) => {
        if (tokenData?.user_profile_id === eventData?.doctorProfileId) {
          const data = {
            ...commonSocketData(eventData),
            chatConsultationRequestPaymentId:
              eventData?.chatConsultationRequestPaymentId,
            chatConsultationId: eventData?.chatConsultationId,
          };
          updateDoctorChatConsultationState('chatStatusData', data);
          localStorage.setItem(
            'chatConsultationId',
            eventData?.chatConsultationId,
          );
          setAcceptReject(false);
          setType('payment_completed');
          setApproval(true);
        }
      });

      // Chat start by doctor
      socket.on('chat_start_by_doctor', (eventData) => {
        if (tokenData?.user_profile_id === eventData?.doctorProfileId) {
          const data = {
            ...commonSocketData(eventData),
            chatConsultationId: eventData?.chatConsultationId,
          };
          getChatDetails(eventData?.chatConsultationId, 'OnGoing');
          updateDoctorChatConsultationState('chatStatusData', data);
          localStorage.setItem(
            'chatConsultationRequestsId',
            eventData?.chatConsultationRequestsId,
          );
          localStorage.setItem(
            'chatConsultationId',
            eventData?.chatConsultationId,
          );
        }
      });

      // chat_live_typing_status
      socket.on('chat_live_typing_status', (eventData) => {
        if (tokenData?.user_profile_id === eventData?.doctorProfileId) {
          const data = {
            ...commonSocketData(eventData),
            chatConsultationId: eventData?.chatConsultationId,
            chatStatus: eventData?.chatStatus,
          };
          updateDoctorChatConsultationState('chatStatusData', data);
          if (
            eventData?.type === 'typingByPatient' ||
            eventData?.type === 'onlineByPatient'
          ) {
            updateDoctorChatConsultationState(
              'onlineTyping',
              eventData?.chatStatus,
            );
          }
          localStorage.setItem(
            'chatConsultationRequestsId',
            eventData?.chatConsultationRequestsId,
          );
          localStorage.setItem(
            'chatConsultationId',
            eventData?.chatConsultationId,
          );
        }
      });

      // chat_consultation_completion
      socket.on('chat_consultation_completion', (eventData) => {
        if (tokenData?.user_profile_id === eventData?.doctorProfileId) {
          const data = {
            ...commonSocketData(eventData),
            chatConsultationId: eventData?.chatConsultationId,
            chatEndedBy: eventData?.chatEndedBy,
            endedByWhom: eventData?.endedByWhom,
          };
          updateDoctorChatConsultationState('chatStatusData', data);
          if (eventData?.endedByWhom === 'patient') {
            updateDoctorChatConsultationState('onlineTyping', '');
            setType('end_by_patient');
            setApproval(true);
          }

          if (eventData?.endedByWhom === 'Time Out') {
            updateDoctorChatConsultationState('onlineTyping', '');
          }

          localStorage.setItem(
            'chatConsultationRequestsId',
            eventData?.chatConsultationRequestsId,
          );
          localStorage.setItem(
            'chatConsultationId',
            eventData?.chatConsultationId,
          );
        }
      });

      // chat_consultation_message
      socket.on('chat_consultation_message', (eventData) => {
        // debugger
        if (tokenData?.user_profile_id === eventData?.doctorProfileId) {
          const data = {
            ...commonSocketData(eventData),
            messageSender: eventData?.messageSender,
            chatMessage: eventData?.chatMessage,
            chatConsutationMessageId: eventData?.chatConsutationMessageId,
            chatConsultationRequestPaymentId:
              eventData?.chatConsultationRequestPaymentId,
            responsePaymentData: eventData?.responsePaymentData,
          };
          updateDoctorChatConsultationState('isScroll', true);

          updateDoctorChatConsultationState('chatStatusData', data);
          if (
            eventData?.chatMessage?.message === "I'm request to chat extension"
          ) {
            updateDoctorChatConsultationState('isChatExtendRequest', true);
          }

          if (
            eventData?.chatMessage?.type === 5 ||
            eventData?.chatMessage?.type === 4
          ) {
            getChatDetails(eventData?.chatConsultationId);
          }

          ChatConstructData(
            eventData,
            tokenData,
            eventData?.chatMessage?.chatData,
            'doctorChatData',
            updateDoctorChatConsultationState,
            false,
          );
          // updateDoctorChatConsultationState('isScroll', false);
        }
      });

      // send medical card details
      socket.on('send_medical_card_to_doctor', (eventData) => {
        // debugger;
        if (tokenData?.user_profile_id === eventData?.doctorProfileId) {
          const data = {
            ...commonSocketData(eventData),
            messageSender: eventData?.messageSender,
            chatMessage: eventData?.chatMessage,
            chatConsutationMessageId: eventData?.chatConsutationMessageId,
            medicalCard: eventData?.medicalCard,
          };
          updateDoctorChatConsultationState('chatStatusData', data);
          updateDoctorChatConsultationState(
            'patientMedicalData',
            eventData?.medicalCard,
          );
        }
      });
    }
    // eslint-disable-next-line
  }, [socket]);

  return (
    <Box
      sx={{ ...rootLayout_style.backgroundSx, ...backgroundStyle }}
      className={`${className}`}
      {...rest}
    >
      <Toaster />
      <Box
        sx={
          isMobile
            ? { ...rootLayout_style.mobileRootSx, ...rootStyle }
            : { ...rootLayout_style.rootSx, ...rootStyle }
        }
      >
        <Outlet />
      </Box>
      <Box display="none">
        <DoctorAcceptReject
          isModalOpen={acceptReject}
          onRejectRequestFnc={onRejectRequest}
          onAcceptRequestFnc={onAcceptRequest}
          handleDialogueClose={acceptRejectCloseFnc}
        />

        <DoctorApproveModal
          isModalOpen={approval}
          handleDialogueClose={approvalCloseFnc}
          seenChatCancelConfirmation={seenChatCancelConfirmationFnc}
          seenChatEndConfirmation={seenChatEndConfirmationFnc}
          startChat={startChatFnc}
          makeOffline={makeOfflineFnc}
          modalType={type}
          endedByName={chatStatusData?.patientName ?? ''}
        />
      </Box>
    </Box>
  );
}
