import { doctorRoutes } from '@hc/routes';
import {
  useDoctorProfile,
  useLanguage,
  useSpeciality,
  useViewPort
} from '@hc/store';
import { Button } from '@hc/ui/atoms';
import {
  DoctorScreenLayout,
  Screenlayout,
  ViewProfile
} from '@hc/ui/components';
import { BasicInfo } from '@hc/ui/components/doctor/basicInfo';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import isEqual from 'react-fast-compare';
import { doctorProfile_style } from './style';
// import { toast } from 'react-hot-toast';

export default function DoctorProfile(props) {
  const { className = '' } = props;
  // const navigate = useNavigate();
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const {
    profile,
    compareOldState,
    progressValue,
    upsertDoctorProfile,
    getDoctorProfileData,
    isIamValided,
  } = useDoctorProfile((state) => ({
    profile: state.profile,
    compareOldState: state.compareOldState,
    progressValue: state.progressValue,
    upsertDoctorProfile: state.upsertDoctorProfile,
    getDoctorProfileData: state.getDoctorProfileData,
    isIamValided: state.isIamValided,
  }));

  const { getSpecialityData } = useSpeciality(
    (state) => ({
      getSpecialityData: state.getSpecialityData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { getLanguageData } = useLanguage(
    (state) => ({
      getLanguageData: state.getLanguageData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const updateBtn = async () => {
    if (isIamValided()) {
      await upsertDoctorProfile(data?.profile?.id);
      await getDoctorProfileData(data?.profile?.id);
      queryClient.invalidateQueries({
        queryKey: ['speciality'],
      });
      queryClient.invalidateQueries({
        queryKey: ['language'],
      });
      await getSpecialityData();
      await getLanguageData();
      localStorage.removeItem('profile');
    }
  };

  useEffect(() => {
    getDoctorProfileData();
  }, []);

  return (
    <>
      {!isMobilePort && (
        <DoctorScreenLayout
          profilePage
          progressValue={progressValue}
          titleRequired
          title="Profile"
          appbarRightButtonName="Update"
          appbarRightButtonRequired
          className={`class ${className}`}
          backRequired
          titleStyle={doctorProfile_style.titleSx}
          backIconStyle={doctorProfile_style.backIconSx}
          backRoute={doctorRoutes?.home}
          onClick={updateBtn}
          disableUpdateButton={compareOldState() ?? false}
          leftSideContainerRootStyle={doctorProfile_style.leftSx}
          rightSideContainerRootStyle={doctorProfile_style.rightSx}
          customLeftBorder={{ borderRight: '0px solid ' }}
          leftChildren={
            <Box sx={doctorProfile_style.totalLeftBox}>
              <ViewProfile
                name={profile?.name ?? ''}
                profile_pic={profile?.profile_pic ?? ''}
                emailId={profile?.email_id ?? ''}
                mobile={`${profile?.country_code} ${profile.mobile_no}`}
              />
            </Box>
          }
          rightChildren={
            <Box sx={doctorProfile_style.totalBox}>
              <BasicInfo />
            </Box>
          }
          rightSideContainer={7}
          leftSideContainer={5}
        />
      )}

      {isMobilePort && (
        <Screenlayout
          profilePage
          progressValue={progressValue}
          isDoctorAppbar
          title="Profile"
          titleStyle={{ color: '#0E1824' }}
          className={`${className}`}
          backRequired
          childrenStyle={{
            minHeight: '60vh',

            pt: {
              xs: 10,
            },
          }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={() => updateBtn()}
                disabled={compareOldState() ?? false}
                buttonStyle={{
                  ...doctorProfile_style.buttonSx,
                  border: compareOldState() ? 'none' : '1px solid ',
                }}
              >
                UPDATE
              </Button>
            </Box>
          }
        >
          <Box>
            <Box sx={doctorProfile_style.totalTopBox}>
              <ViewProfile
                name={profile?.name ?? ''}
                profile_pic={profile?.profile_pic ?? ''}
                emailId={profile?.email_id ?? ''}
                mobile={`${profile?.country_code} ${profile.mobile_no}`}
              />
            </Box>
            <Box sx={doctorProfile_style.totalBotomSx}>
              <BasicInfo />
            </Box>
          </Box>
        </Screenlayout>
      )}
    </>
  );
}

DoctorProfile.propTypes = {
  className: PropTypes.string,
};
